@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-Bold.eot');
    src: url('RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('RobotoCondensed-Bold.woff2') format('woff2'),
        url('RobotoCondensed-Bold.woff') format('woff'),
        url('RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-Light.eot');
    src: url('RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
        url('RobotoCondensed-Light.woff2') format('woff2'),
        url('RobotoCondensed-Light.woff') format('woff'),
        url('RobotoCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-LightItalic.eot');
    src: url('RobotoCondensed-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('RobotoCondensed-LightItalic.woff2') format('woff2'),
        url('RobotoCondensed-LightItalic.woff') format('woff'),
        url('RobotoCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-Italic.eot');
    src: url('RobotoCondensed-Italic.eot?#iefix') format('embedded-opentype'),
        url('RobotoCondensed-Italic.woff2') format('woff2'),
        url('RobotoCondensed-Italic.woff') format('woff'),
        url('RobotoCondensed-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-BoldItalic.eot');
    src: url('RobotoCondensed-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url('RobotoCondensed-BoldItalic.woff') format('woff'),
        url('RobotoCondensed-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-Regular.eot');
    src: url('RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
        url('RobotoCondensed-Regular.woff2') format('woff2'),
        url('RobotoCondensed-Regular.woff') format('woff'),
        url('RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

