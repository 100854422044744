@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Black.eot');
    src: local('Neue Haas Unica Black'), local('NeueHaasUnica-Black'),
        url('NeueHaasUnica-Black.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Black.woff2') format('woff2'),
        url('NeueHaasUnica-Black.woff') format('woff'),
        url('NeueHaasUnica-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Medium.eot');
    src: local('Neue Haas Unica Medium'), local('NeueHaasUnica-Medium'),
        url('NeueHaasUnica-Medium.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Medium.woff2') format('woff2'),
        url('NeueHaasUnica-Medium.woff') format('woff'),
        url('NeueHaasUnica-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-BoldItalic.eot');
    src: local('Neue Haas Unica Bold Italic'), local('NeueHaasUnica-BoldItalic'),
        url('NeueHaasUnica-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-BoldItalic.woff2') format('woff2'),
        url('NeueHaasUnica-BoldItalic.woff') format('woff'),
        url('NeueHaasUnica-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ExtBlkIta.eot');
    src: local('Neue Haas Unica ExtBlk Ita'), local('NeueHaasUnica-ExtBlkIta'),
        url('NeueHaasUnica-ExtBlkIta.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ExtBlkIta.woff2') format('woff2'),
        url('NeueHaasUnica-ExtBlkIta.woff') format('woff'),
        url('NeueHaasUnica-ExtBlkIta.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ExtraBold.eot');
    src: local('Neue Haas Unica Extra Bold'), local('NeueHaasUnica-ExtraBold'),
        url('NeueHaasUnica-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ExtraBold.woff2') format('woff2'),
        url('NeueHaasUnica-ExtraBold.woff') format('woff'),
        url('NeueHaasUnica-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-MediumItalic.eot');
    src: local('Neue Haas Unica Medium Italic'), local('NeueHaasUnica-MediumItalic'),
        url('NeueHaasUnica-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-MediumItalic.woff2') format('woff2'),
        url('NeueHaasUnica-MediumItalic.woff') format('woff'),
        url('NeueHaasUnica-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ExtraBoldItalic.eot');
    src: local('Neue Haas Unica ExtBd Ita'), local('NeueHaasUnica-ExtraBoldItalic'),
        url('NeueHaasUnica-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ExtraBoldItalic.woff2') format('woff2'),
        url('NeueHaasUnica-ExtraBoldItalic.woff') format('woff'),
        url('NeueHaasUnica-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Italic.eot');
    src: local('Neue Haas Unica Italic'), local('NeueHaasUnica-Italic'),
        url('NeueHaasUnica-Italic.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Italic.woff2') format('woff2'),
        url('NeueHaasUnica-Italic.woff') format('woff'),
        url('NeueHaasUnica-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Light.eot');
    src: local('Neue Haas Unica Light'), local('NeueHaasUnica-Light'),
        url('NeueHaasUnica-Light.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Light.woff2') format('woff2'),
        url('NeueHaasUnica-Light.woff') format('woff'),
        url('NeueHaasUnica-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ExtLtIta.eot');
    src: local('Neue Haas Unica ExtLt Ita'), local('NeueHaasUnica-ExtLtIta'),
        url('NeueHaasUnica-ExtLtIta.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ExtLtIta.woff2') format('woff2'),
        url('NeueHaasUnica-ExtLtIta.woff') format('woff'),
        url('NeueHaasUnica-ExtLtIta.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Bold.eot');
    src: local('Neue Haas Unica Bold'), local('NeueHaasUnica-Bold'),
        url('NeueHaasUnica-Bold.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Bold.woff2') format('woff2'),
        url('NeueHaasUnica-Bold.woff') format('woff'),
        url('NeueHaasUnica-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ThinItalic.eot');
    src: local('Neue Haas Unica Thin Italic'), local('NeueHaasUnica-ThinItalic'),
        url('NeueHaasUnica-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ThinItalic.woff2') format('woff2'),
        url('NeueHaasUnica-ThinItalic.woff') format('woff'),
        url('NeueHaasUnica-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-LightItalic.eot');
    src: local('Neue Haas Unica Light Italic'), local('NeueHaasUnica-LightItalic'),
        url('NeueHaasUnica-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-LightItalic.woff2') format('woff2'),
        url('NeueHaasUnica-LightItalic.woff') format('woff'),
        url('NeueHaasUnica-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ExtraLight.eot');
    src: local('Neue Haas Unica Extra Light'), local('NeueHaasUnica-ExtraLight'),
        url('NeueHaasUnica-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ExtraLight.woff2') format('woff2'),
        url('NeueHaasUnica-ExtraLight.woff') format('woff'),
        url('NeueHaasUnica-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Regular.eot');
    src: local('Neue Haas Unica Regular'), local('NeueHaasUnica-Regular'),
        url('NeueHaasUnica-Regular.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Regular.woff2') format('woff2'),
        url('NeueHaasUnica-Regular.woff') format('woff'),
        url('NeueHaasUnica-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-ExtraBlack.eot');
    src: local('Neue Haas Unica Extra Black'), local('NeueHaasUnica-ExtraBlack'),
        url('NeueHaasUnica-ExtraBlack.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-ExtraBlack.woff2') format('woff2'),
        url('NeueHaasUnica-ExtraBlack.woff') format('woff'),
        url('NeueHaasUnica-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-BoldItalic_1.eot');
    src: local('Neue Haas Unica Bold Italic'), local('NeueHaasUnica-BoldItalic'),
        url('NeueHaasUnica-BoldItalic_1.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-BoldItalic_1.woff2') format('woff2'),
        url('NeueHaasUnica-BoldItalic_1.woff') format('woff'),
        url('NeueHaasUnica-BoldItalic_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url('NeueHaasUnica-Thin.eot');
    src: local('Neue Haas Unica Thin'), local('NeueHaasUnica-Thin'),
        url('NeueHaasUnica-Thin.eot?#iefix') format('embedded-opentype'),
        url('NeueHaasUnica-Thin.woff2') format('woff2'),
        url('NeueHaasUnica-Thin.woff') format('woff'),
        url('NeueHaasUnica-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

