@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Black.eot');
    src: url('MyriadPro-Black.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Black.woff2') format('woff2'),
        url('MyriadPro-Black.woff') format('woff'),
        url('MyriadPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightIt.eot');
    src: url('MyriadPro-LightIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-LightIt.woff2') format('woff2'),
        url('MyriadPro-LightIt.woff') format('woff'),
        url('MyriadPro-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-CondIt.eot');
    src: url('MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-CondIt.woff2') format('woff2'),
        url('MyriadPro-CondIt.woff') format('woff'),
        url('MyriadPro-CondIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BlackSemiCn.eot');
    src: url('MyriadPro-BlackSemiCn.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BlackSemiCn.woff2') format('woff2'),
        url('MyriadPro-BlackSemiCn.woff') format('woff'),
        url('MyriadPro-BlackSemiCn.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiCn.eot');
    src: url('MyriadPro-SemiCn.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiCn.woff2') format('woff2'),
        url('MyriadPro-SemiCn.woff') format('woff'),
        url('MyriadPro-SemiCn.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Bold.eot');
    src: url('MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Bold.woff2') format('woff2'),
        url('MyriadPro-Bold.woff') format('woff'),
        url('MyriadPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightSemiCn.eot');
    src: url('MyriadPro-LightSemiCn.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-LightSemiCn.woff2') format('woff2'),
        url('MyriadPro-LightSemiCn.woff') format('woff'),
        url('MyriadPro-LightSemiCn.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightSemiCnIt.eot');
    src: url('MyriadPro-LightSemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-LightSemiCnIt.woff2') format('woff2'),
        url('MyriadPro-LightSemiCnIt.woff') format('woff'),
        url('MyriadPro-LightSemiCnIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Cond.eot');
    src: url('MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Cond.woff2') format('woff2'),
        url('MyriadPro-Cond.woff') format('woff'),
        url('MyriadPro-Cond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldCond.eot');
    src: url('MyriadPro-SemiboldCond.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiboldCond.woff2') format('woff2'),
        url('MyriadPro-SemiboldCond.woff') format('woff'),
        url('MyriadPro-SemiboldCond.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BoldCondIt.eot');
    src: url('MyriadPro-BoldCondIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BoldCondIt.woff2') format('woff2'),
        url('MyriadPro-BoldCondIt.woff') format('woff'),
        url('MyriadPro-BoldCondIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BoldCond.eot');
    src: url('MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BoldCond.woff2') format('woff2'),
        url('MyriadPro-BoldCond.woff') format('woff'),
        url('MyriadPro-BoldCond.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BlackSemiCnIt.eot');
    src: url('MyriadPro-BlackSemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BlackSemiCnIt.woff2') format('woff2'),
        url('MyriadPro-BlackSemiCnIt.woff') format('woff'),
        url('MyriadPro-BlackSemiCnIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BoldIt.eot');
    src: url('MyriadPro-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BoldIt.woff2') format('woff2'),
        url('MyriadPro-BoldIt.woff') format('woff'),
        url('MyriadPro-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightCondIt.eot');
    src: url('MyriadPro-LightCondIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-LightCondIt.woff2') format('woff2'),
        url('MyriadPro-LightCondIt.woff') format('woff'),
        url('MyriadPro-LightCondIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BlackCond.eot');
    src: url('MyriadPro-BlackCond.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BlackCond.woff2') format('woff2'),
        url('MyriadPro-BlackCond.woff') format('woff'),
        url('MyriadPro-BlackCond.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldSemiCn.eot');
    src: url('MyriadPro-SemiboldSemiCn.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiboldSemiCn.woff2') format('woff2'),
        url('MyriadPro-SemiboldSemiCn.woff') format('woff'),
        url('MyriadPro-SemiboldSemiCn.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BoldSemiCn.eot');
    src: url('MyriadPro-BoldSemiCn.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BoldSemiCn.woff2') format('woff2'),
        url('MyriadPro-BoldSemiCn.woff') format('woff'),
        url('MyriadPro-BoldSemiCn.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldIt.eot');
    src: url('MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiboldIt.woff2') format('woff2'),
        url('MyriadPro-SemiboldIt.woff') format('woff'),
        url('MyriadPro-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Semibold.eot');
    src: url('MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Semibold.woff2') format('woff2'),
        url('MyriadPro-Semibold.woff') format('woff'),
        url('MyriadPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightCond.eot');
    src: url('MyriadPro-LightCond.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-LightCond.woff2') format('woff2'),
        url('MyriadPro-LightCond.woff') format('woff'),
        url('MyriadPro-LightCond.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldSemiCnIt.eot');
    src: url('MyriadPro-SemiboldSemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiboldSemiCnIt.woff2') format('woff2'),
        url('MyriadPro-SemiboldSemiCnIt.woff') format('woff'),
        url('MyriadPro-SemiboldSemiCnIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Regular.eot');
    src: url('MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Regular.woff2') format('woff2'),
        url('MyriadPro-Regular.woff') format('woff'),
        url('MyriadPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiCnIt.eot');
    src: url('MyriadPro-SemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiCnIt.woff2') format('woff2'),
        url('MyriadPro-SemiCnIt.woff') format('woff'),
        url('MyriadPro-SemiCnIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldCondIt.eot');
    src: url('MyriadPro-SemiboldCondIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-SemiboldCondIt.woff2') format('woff2'),
        url('MyriadPro-SemiboldCondIt.woff') format('woff'),
        url('MyriadPro-SemiboldCondIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-It.eot');
    src: url('MyriadPro-It.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-It.woff2') format('woff2'),
        url('MyriadPro-It.woff') format('woff'),
        url('MyriadPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BlackIt.eot');
    src: url('MyriadPro-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-BlackIt.woff2') format('woff2'),
        url('MyriadPro-BlackIt.woff') format('woff'),
        url('MyriadPro-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

